.error-galago-page {
  padding: 54px 40px 40px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  #error-page-button-holder {
    width: 200px;
  }
}
