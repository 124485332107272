@import './_variables.module.scss';

.async-flight-locations {
  font-family: 'General Sans', sans-serif;
  > div[aria-label='menu-options'] {
    padding: unset;
    width: 552px !important;
    > a {
      padding-left: unset;
      padding-right: unset;
      padding-bottom: 10px;
      padding-top: 10px;
      border-bottom: 1px solid #ddd;

      > span {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    > a:last-child {
      border-bottom: unset;
    }
  }

  ::placeholder {
    color: #a8a8a8;
    font-weight: 400;
    font-size: 14px !important;
  }

  input {
    font-size: 14px;
  }
}

#async-flying-from,
#async-flying-to {
  > a:hover {
    background-color: $primary-light;

    div {
      color: $primary;
    }

    div > svg {
      path {
        fill: $primary;
      }
    }
  }

  > a:focus,
  > a:active,
  > a:visited {
    background-color: $primary-light;
  }
}

#async-hotel {
  > a:hover {
    background-color: $primary-light;

    div {
      color: $primary;
    }

    div > svg {
      path {
        stroke: $primary;
      }
    }

    span {
      color: $primary;
    }
  }

  > a:focus,
  > a:active,
  > a:visited {
    background-color: $primary-light;
  }
}
