.mobile-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transform: translateY(100%);
  transition: transform 0.2s ease-out;
  z-index: 999;
}

.mobile-overlay.show {
  transform: translateY(0);
  transition: transform 0.2s ease-out;
}

.header-overlay {
  position: relative;
  text-align: center;
  padding: 1rem;
  span {
    position: absolute;
    left: 10%;
    cursor: pointer;
  }
  p {
    width: 100%;
    font-family: 'General Sans';
    font-weight: 500;
    font-size: 16px;
  }
}

#airport-location-holder {
  > div:first-child,
  > div:last-child {
    position: relative;

    .clear-text-svg {
      position: absolute !important;
      z-index: 1;
      bottom: 17px;
      right: 10px;
      cursor: pointer;
    }
  }
}
