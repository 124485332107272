@import './_variables.module.scss';

$danger: $error;
$galago-color: $primary-teal-50;
.btn-light {
  border-color: $primary !important;
  color: $primary !important;
  background-color: #f7f7f7;
}

.btn-light:hover {
  background-color: $primary-light-hover;
}

.btn-secondary {
  color: white !important;
}

.continue-btn:disabled {
  background-color: #adadad !important;
  border-color: #adadad;
}

// * {
//   font-family: $font-family-general-sans  !important;
//   font-size: $font-size-16;
//   font-weight: $font-weight-medium !important;
// }
