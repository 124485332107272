.profile {
  font-family: 'General Sans';
  max-width: 1150px;
  display: flex;
  margin-top: 4rem;

  .profile-tabs {
    max-width: 400px;
    width: 100%;

    .tabs-container {
      max-width: 300px;

      .tabs {
        padding: 20px 10px;
        border-bottom: 1px solid #e7eaeb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: 0.2s ease-in-out;

        &:hover {
          color: #006FA9;
          border-bottom: 1px solid #006FA9;
        }
        p {
          font-weight: 500;
        }
      }
    }
  }
  .profile-forms {
    max-width: 724px;
    width: 100%;
    align-items: start;

    .form-holder {
      width: 100%;
    }
  }

  .profileRow {
    display: flex;
    width: 1232px;
    justify-content: center;
    align-self: center;
    gap: 180px;

    .profileHeader {
      display: flex;
      width: 536px;
      align-items: center;
      gap: 24px;
      flex-shrink: 0;
    }
  }

  .image {
    border: 2px solid #4fa2b4;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: #006FA9;
    position: relative;
    display: flex;

    .initial {
      display: flex;
      color: white;
      font-size: 54px;
      margin: auto;
      font-family: 'Satoshi';
      font-weight: 500;
      text-transform: uppercase;
    }

    .cameraIcon {
      position: absolute;
      z-index: 1;
      display: flex;
      background-color: white;
      border-radius: 100px;

      border: 1px solid #ffffff;
      bottom: 5px;
      right: 0;

      & {
        align-items: center;
        justify-content: center;
      }
    }

    img {
      height: 98px;
      width: 98px;
      border-radius: 50%;
      margin-top: -0.6px;
    }
  }
  .profileDetails {
    font-family: 'General Sans';

    .profile-info {
      margin: 0;
      padding: 0;
      font-size: 16px;
      color: #333;
    }
    .profile-name {
      font-size: 34px;
      margin: 0;
      padding: 0;
      text-transform: capitalize;
      color: #333;
    }
  }

  .card {
    display: flex;
    padding: 24px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    border-radius: 12px;
    border: 1px solid var(--border-colors-border-primary, #d9d9d9);
    background: #fff;
    font-family: 'General Sans' !important;
  }

  // General styles
  .title {
    color: #858585;
    font-weight: 500;
    font-size: 16px;
    padding: 8px 0px !important;
    width: 8rem;
  }
  .edit {
    font-size: 14px;
    color: #006FA9;
    font-weight: 500;
  }
  .text {
    margin: 0;
    padding: 0;
    // font-size: 13px;
    // font-weight: 500;
  }
  .delete-info-btn {
    background-color: #faf0ef;
    padding: 14px 40px;
    border: none;
    color: #bd0a00;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .tealtextColor {
    color: #006FA9;
  }
  .font-medium {
    font-weight: 500;
  }
  .Button {
    border-radius: 6px !important;
    padding: 12px 40px;
    font-size: 14px;
    margin-bottom: 16px;
  }
  .content {
    font-size: 14px;
    color: #333333;
    font-weight: 500;
  }
  #modalText {
    margin: 0;
    padding: 0;
  }
  #email {
    font-weight: 400;
  }
  .lime {
    color: #217c38;
  }
  .red {
    color: #bd0a00;
  }

  // Informations

  .profile-data {
    display: flex;
    flex-direction: row;
    padding: 12px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    font-family: 'General Sans';
    border-bottom: 1px solid var(--color-shades-gray-600, #e7eaeb);

    &:last-child {
      border-bottom: unset;
    }
  }
  .row-info {
    &:nth-child(n + 2):nth-child(-n + 5) {
      border-bottom: 1px solid var(--color-shades-gray-600, #e7eaeb);
    }
    // &:last-child {
    //   border-bottom: 1px solid var(--color-shades-gray-600, #e7eaeb);
    // }
  }
}

.dropFilesZone {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  height: 226px;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;

  .textGuide {
    font-family: 'General Sans';
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }

  .dropHereText {
    font-family: 'General Sans';
    font-weight: 400;
    font-size: 13px;
    color: #adadad;
  }
}

.image-prev-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.controller-div,
.error-div {
  display: flex;
  flex-direction: 'row';
}

.disable-button {
  background-color: #d9d9d9 !important;
  color: #fff !important;
  border: none !important;
  cursor: not-allowed;
}

.disable-button:hover {
  cursor: not-allowed;
}

.errorText {
  color: #bd0a00;
  font-size: 13px;
  font-family: 'General Sans';
  font-weight: 500;
  margin-left: 5px;
}
