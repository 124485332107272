#policies{

  .border{

    > .flex{

      font-size: 16px !important;
      
      .w-75{
        font-size: 1em !important;

        span.underline{
          font-style: italic;
        }
      }

    }

  }
}