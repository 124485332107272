@import './_variables.module.scss';
$small: 475px;
$medium: 786px;
$large: 1024px;

#airport-location-holder {
  > div:first-child,
  > div:last-child {
    position: relative;

    .clear-text-svg {
      position: absolute !important;
      z-index: 1;
      bottom: 17px;
      right: 10px;
      cursor: pointer;
    }
  }
}

.no-link-style {
  text-decoration: none;
  color: $primary;
}

.no-link-style:hover,
.no-link-style:active {
  color: white; /* Change text color to white on hover and active states */
}

.top-menu {
  border-bottom: 1px solid rgba(79, 162, 180, 0.2);
  padding: 20px 0;
  font-family: 'General-sans', sans-serif;
  display: flex;
  gap: 24px;

  @media screen and (max-width: $medium) {
    display: none;
  }

  .menu {
    cursor: pointer;

    .caret {
      transition: 0.2s ease-in;
    }
    .rotate {
      transform: rotate(180deg);
      transition: 0.2s ease-in;
      svg path {
        stroke: $primary;
      }
    }
  }

  .hide {
    display: none;
  }

  .menu-options-passenger {
    position: absolute;
    top: 30px;
  }


  .menu-options {
    background-color: white;
    z-index: 2;
    position: absolute;
    top: 40px;
    border: 1px solid $primary-light-active;
    max-width: 210px;
    width: max-content;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px rgba(60, 64, 67, 0.15);
    box-shadow: 0px 4px 4px 0px rgba(60, 64, 67, 0.3);

    ul {
      padding: 0;

      li {
        padding: 6px 12px;
        font-size: 14px;
        font-family: 'General-sans', sans-serif;
      }
    }

    ul li:hover {
      background-color: $primary-light-active;
    }
  }
}
.first-row {
  margin: 16px 0;
  @media screen and (max-width: $medium) {
    flex-wrap: wrap;

    div {
      max-width: 800px;
    }
  }

  .search-btn {
    max-width: 200px;
    width: 100%;
    align-self: last baseline;
    @media screen and (max-width: $medium) {
      max-width: 800px;
    }
  }
}
.flight-calendar-holder {
  position: absolute;
  z-index: 1;
  width: 350px;
}

.flight-calendar-holder-mobile {
  .react-calendar {
    box-shadow: none;
    width: 100% !important;
  }
}

.tab_container {
  padding: 22px 20px;
}

.switch-mobile:hover {
  transform: rotate(180deg);
  transition: 0.5s all ease-in-out;
}

.svg-arrow-mobile {
  display: none;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
  width: 24px;
  height: 24px;

  @media screen and (max-width: $medium) {
    display: block;
    position: absolute !important;
    top: 90px;
    right: 20px;
  }
}
.svg-arrow {
  align-self: last baseline;
  @media screen and (max-width: $medium) {
    display: none;
  }
}
.departure-column {
  display: flex;
  gap: 10px;

  @media screen and (max-width: $medium) {
    flex-direction: column;
    margin-top: 20px;
  }
}
// .switch {
//   @media screen and (max-width: $small) {
//     display: none;
//   }
// }
.flying {
  @media screen and (max-width: $medium) {
    display: flex;
    flex-direction: column;
  }
}

.date-range-label {
  margin-bottom: 8px;
  font-family: $font-family-satoshi;
  font-size: 15px;
  width: fit-content;
}
.date-input {
  padding: 12px 28px 12px 12px;
  border-radius: 12px;
  font-family: $font-family-general-sans;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-width: 286px;
  width: 100%;
  position: relative;
  height: 49px;
  cursor: pointer;

  .date-field {
    border: none;
    display: flex;
    gap: 8px;
    align-items: center;

    .date-label {
      font-size: $font-size-10;
      font-weight: $font-weight-medium;
      color: $secondary;
    }
    .date-value {
      font-size: $font-size-13;
      font-weight: 400;
      color: $charcoal-100;
      margin: 0;
    }
  }
}
.date-range-calendar {
  position: absolute;
  top: 95%;
  z-index: 1;
}


.date-range-calendar-mobile{
.rdrCalendarWrapper {
  width: 100% !important;

  .rdrMonth{
    width: 100% ;
  }
}
}

// .rdrDateRangeWrapper {
//   width: 100% !important;
// }

.date-range-calendar-mobile {
  width: 100%;
  background-color: red;
}

.mobile-last-row {
  width: 100%;
  justify-content: space-between;
  display: none;
  font-family: 'General-sans', sans-serif;

  .menu-options-mobile{
    background-color: white;
    border-radius: 8px;
    ul {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 24px;

      li {
        padding: 24px 14px;
        font-size: 14px;
        font-family: 'General-sans', sans-serif;
        border: 1px solid rgba(242, 242, 242, 1);
        border-radius: 12px;
        cursor: pointer;
      }
    }

    ul li:hover {
      background-color: rgba(250, 254, 255, 1);
      border-color: $primary-light-active;
    }

    ul li:focus{
      background-color: red;
    }
  
  }

  .mobile-passengers {
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 8px;
    display: flex;
  }

  // Passengers dropdown override css 
  .passengers-mobile{
    div {
      max-width: 100% !important;
      box-shadow: none !important;
    }
  }

  @media screen and (max-width: $medium) {
    display: flex;
  }
}
