.flight-info-header {
  display: flex;
  padding: 20px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;

  p{color: #006FA9; font-size: 14px;}
  > div {
    color: #333;
    // font-family: 'Satoshi-Medium';
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
  }
}

.flight-info-body {
  display: flex;
  padding: 0px 32px 32px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  // gap: 10px;

  .flight-start-stop-info {
    display: flex;
    align-items: center;
    align-self: stretch;

    > div:nth-child(2) {
      > span {
        > svg {
          margin-right: 0.5em;
        }
      }
    }

    > div:first-child {
      width: 17.9%;
    }

    > div:last-child {
      border-left: 1px solid #d9d9d9;
      padding: 20px 0px;

      > span {
        margin-left: 14px;
        color: #333;
        margin-bottom: 8px;
      }

      > span:first-child {
        border-radius: 20px;
        background: #d0f0ef;
        color: #006FA9;
        display: flex;
        padding: 4px 6px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        margin-bottom: 10px;

        > img {
          vertical-align: sub;
        }
      }
    }
  }

  .flight-start {
    display: flex;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #006FA9;
    background: var(--color-shades-white-800, #fff);

    > div:first-child {
      width: 18%;
      padding-top: 10px;

      > span:first-child {
        position: relative;
        padding-left: 16px;
        color:#333;
        font-size: 16px;
        border-right: 1px solid #d9d9d9;
        padding-bottom: 2px;
      }

      > span:last-child {
        position: relative;
        padding-left: 16px;
        color: #808080;
        padding-bottom: 10px;
        border-right: 1px solid #d9d9d9;
      }

      > span:first-child::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: #006FA9;
        border-radius: 10px;
        right: -5.5px;
        top: 7px;
        z-index: 1;
      }
      > span:first-child::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 10px;
        right: -5.5px;
        top: 0px;
        z-index: 0;
      }
    }

    > div:last-child {
      > span {
        padding-left: 14px;
      }

      > span:first-child {
        position: relative;
        color:#333;
        font-size: 16px;
        padding-bottom: 2px;
      }

      > span:last-child {
        position: relative;
        color:#333;
        font-size: 14px;
      }
    }
  }

  .flight-stop {
    display: flex;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #006FA9;
    background: var(--color-shades-white-800, #fff);

    > div:first-child {
      width: 18%;
      padding-bottom: 10px;

      > span:last-child {
        position: relative;
        padding-left: 16px;
        color: #808080;
        font-size: 14px;
      }

      > span:first-child {
        position: relative;
        color: #333;
        font-size: 16px;
        padding-left: 16px;
        padding-top: 10px;
        padding-bottom: 2px;
        border-right: 1px solid #d9d9d9;
      }

      > span:first-child::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: #006FA9;
        border-radius: 10px;
        right: -5.5px;
        bottom: 7px;
        z-index: 1;
      }
      > span:first-child::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 10px;
        right: -5.5px;
        bottom: 0px;
        z-index: 0;
      }
    }

    > div:last-child {
      > span {
        padding-left: 14px;
      }

      > span:first-child {
        position: relative;
        color:#333;
        font-size: 16px;
        padding-bottom: 2px;
      }

      > span:last-child {
        position: relative;
        color:#333;
        font-size: 14px;
      }
    }
  }
}

.stop-information {
  display: flex;
  padding: 14px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--primary-primary-100, #006FA9);
  background: #e4eff0;
  margin:20px 0 0 0;
  color: #006FA9;
  font-size: 14px;
}

.flight-info-foot {
  > div {
    display: flex;
    padding: 0px 32px 32px 32px;
    flex-direction: column;
    align-items: flex-start;
    > h3 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 2px;
      align-self: stretch;
      font-size: 18px;
      color: #333;
      margin: 0;
    }

    > div {
      display: flex;
      padding: 20px 0px 0px 0;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      border-bottom: 1px solid #ddd;

      h3 {
        color: #333;
        font-size: 16px;
        margin-bottom: 4px;
      }
      p{color: #333; font-size: 14px; line-height: normal;}
    }
  }

  > div:last-child {
    > div {
      border-bottom: transparent;
    }
  }
}

.flight-footer{
  div:first-child{
    color: #333;
    p{font-size: 14px;}
    h5{margin: 0; color: #006FA9; font-size: 18px;}
  }

  box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.20);
  border-top: none !important;
}