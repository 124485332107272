.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.9);
}

.hotel-amenities-modal-container {
  font-family: 'General Sans', sans-serif;
  font-weight: 500;

  ::-webkit-scrollbar-thumb {
    background-color: #adadad;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #006FA9;
  }
  .modal-content {
    border-radius: 12px;
  }

  .modal-title {
    font-size: 20px;
  }

  .modal-body {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 34px 32px;
    max-height: 82vh;
    overflow-y: auto;
    div {
      display: flex;
      align-items: center;
      gap: 14px;
      padding: 10px;
      border-bottom: 1px solid #d9d9d9;
    }
  }

  .modal-footer {
    border-top: none;
  }
}
