.react-calendar {
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 12px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  line-height: 1.125em;
  padding: 16px;
  border: unset !important;
}

.react-calendar__tile {
  // flex: 0 0 12.2857% !important;
}

.react-calendar__navigation button {
  color: #006FA9;
  background: none;
  font-size: 14px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #cadbde;
}
.react-calendar__navigation button[disabled] {
  // background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */

.react-calendar__tile--now {
  // background: #006FA9 !important;
  // color: #fff !important;
  border-radius: 12px !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #E6F1F3;
  border-radius: 12px !important;
  color: #006FA9;
  // border-radius: 6px;
}

.react-calendar__tile--now {
  // background: #6f48eb33;
  background: #fff;
  border-radius: 12px !important;
  // font-weight: bold;
  // color: #006FA9;
  color: #212529;

  // &:enabled:hover,
  // &:enabled:focus {
  //   // background: #6f48eb33;
  //   border-radius: 12px;
  //   // font-weight: bold;
  //   background: #006FA9;
  //   color: #fff;
  // }
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  // background: #cadbde;
}
.react-calendar__tile--active {
  background: #006FA9;
  border-radius: 12px !important;
  // font-weight: bold;
  color: white;
  transition: 0.1s all ease-in-out;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  // background: #006FA9;
  // color: white;
  background: #006FA9;
  color: white;
  transition: 0.1s all ease-in-out;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #cadbde;
}
.react-calendar__tile--range {
  background: #cadbde;
  color: #006FA9;
  border-radius: 0;
}
.react-calendar__tile--rangeStart {
  // border-top-right-radius: 0;
  // border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-radius: 6px;
  background: #006FA9;
  color: white;
}
.react-calendar__tile--rangeEnd {
  // border-top-left-radius: 0;
  // border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-radius: 6px;
  background: #006FA9;
  color: white;
}


div.react-calendar__month-view__days {

  // > button{
  //   width: 36px !important;
  // }

  > button[disabled]{
    background-color: transparent;
    color: rgb(210, 210, 210);
  }
}