@import './_variables.module.scss';

#image-section {
  background-image: url('https://galago-assets.s3.ap-southeast-1.amazonaws.com/galago-v1-web/galago-v1-web/src/resources/images/homepage/Discover1.png');
  background-position: center;
  background-size: cover;
  height: 200px;
}

.adventure {
  padding: 200px 0;

  @media screen and (max-width: $medium) {
    padding: 12px 24px;
  }

  .promo-container {
    text-align: center;

    .promo-grid {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 20px;
    }

    .promo-title {
      display: flex;
      flex-direction: column;
      gap: 24px;

      h1 {
        color: $primary;
        font-family: $font-family-syne;
        font-weight: $font-weight-bold;
        font-optical-sizing: auto;
      }
      h2 {
        color: $charcoal-60;
        line-height: normal;
      }
    }

    .promo-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 14px;

      .img-card {
        position: relative;
        .card-ribbon {
          margin: 15px;
          right: 5px;
          padding: 6px 14px;
          background-color: $whitesmoke-10;
          border-radius: 6px;
          font-size: 12px;
          font-weight: 700;
          color: $primary;
        }
      }

      .promo-btn {
        padding: 10px 16px;
        font-weight: $font-weight-semibold;
        background-color: $whitesmoke-10;
        font-family: $font-family-general-sans;
        font-size: $font-size-14;
        font-weight: $font-weight-bold;
        color: $primary;
        display: flex;
        align-items: center;
        border-radius: 6px;
        &:hover {
          background-color: $primary;
          font-weight: $font-weight-semibold;
          color: $whitesmoke-10;
        }
      }

      .promo-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 10px;

        h2 {
          margin: 0;
          font-weight: 700;
        }

        p {
          color: $primary;
          font-weight: $font-weight-bold;
          font-family: $font-family-general-sans;
        }
      }
    }
  }
}

#cta {
  padding: 100px 396px;
  background-color: $primary;
  color: $whitesmoke-10;
  text-align: center;

  .cta__title {
    font-weight: $font-weight-semibold;
    line-height: normal;
    margin: 0px;
    font-size: $font-size-28;
  }
}

#divider {
  background-image: url('https://galago-assets.s3.ap-southeast-1.amazonaws.com/homepage/homepage/new+siargao.png');
  background-position: center;
  object-fit: contain;
  height: 50vh;
  max-height: 25vh;

  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    font-size: $font-size-28;
    font-weight: $font-weight-bold;
    line-height: 34px;
  }
}

.get-ready {
  background-color: $primary;
  color: $whitesmoke-10;
  position: relative;
  height: 300px;

  h1 {
    font-size: $font-size-28;
    font-weight: $font-weight-bold;
    line-height: 34px;
  }
  #image-cta {
    flex-direction: column;

    p {
      padding: 0;
      margin: 0;
    }
  }
  img {
    height: 480px;
    width: 481px;
    position: absolute;
    top: -180px;
  }
}

.travel-guides {
  display: grid;
  place-items: center;
  background-color: #f7f7f7;
  padding: 100px 104px;

  @media screen and (max-width: $medium) {
    padding: 12px 24px;
  }

  .travel-container {
    // margin: 0 auto;
    max-width: 1232px;
    width: 100%;
    // text-align: center;

    .travel-header {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: $medium) {
        padding: 0px 24px 0px 24px;
      }

      .travel-title {
        display: flex;
        flex-direction: column;
        gap: 14px;
        margin-bottom: 64px;
        @media screen and (max-width: $medium) {
          gap: 6px;
        }

        h1 {
          color: $primary;
          font-family: $font-family-satoshi;
          font-weight: 900;
          font-optical-sizing: auto;
          font-size: 28px;
          color: $charcoal-60;
        }

        h2 {
          color: $charcoal-60;
          line-height: normal;
          font-size: 16px;
        }
      }

      .travel-btn {
        background-color: white;
        align-self: center;
        cursor: pointer;
        @media screen and (max-width: $medium) {
          display: none;
        }
      }
    }

    .travel-column {
      display: flex;
      width: 100%;
      max-width: 1232px;
      gap: 60px;
      margin: 0;

      @media screen and (max-width: $medium) {
        gap: 40px;
      }
      .travel-guides-card {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding-bottom: 100px;
        width: 100%;
        max-width: 371px;

        @media screen and (max-width: $medium) {
          gap: 12px;
          padding: 0 24px !important;
          max-width: max-content;
        }
        .image img {
          width: 100%;
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: start;
          gap: 24px;

          @media screen and (max-width: $medium) {
            gap: 12px !important;
          }
        }
        .blog__title_homepage {
          color: $charcoal-60;
          font-weight: 700;
          margin: 0;
          line-height: normal;
          font-family: $font-family-satoshi;
          font-size: 20px;
        }
        p {
          color: #333333d9;
          font-family: $font-family-general-sans;
          padding: 0;
          margin: 0;
          font-weight: 500;
          font-size: 16px;
        }
        p:nth-last-child(1) {
          color: #333333a6;
          font-size: $font-size-17;
          font-weight: $font-weight-medium;
        }
        a {
          text-decoration: none;
          cursor: pointer;
          color: $primary;
          font-weight: $font-weight-medium;

          &:hover {
            color: $primary-60;
          }
        }
      }
    }

    .mobile-view-all {
      display: none;

      @media screen and (max-width: $medium) {
        display: block;
        margin: 24px !important;
      }
    }
  }
}

.acc-aff-container {
  background: url('http://47.245.126.170:30085/api/v1/buckets/walktheplanet-assets/objects/download?preview=true&prefix=Accreditations-Bg.jpg&version_id=null');
  height: 752px;
  background-position: center;
  background-size: cover;
  width: 100%;

  @media screen and (max-width: $medium) {
    height: 464px;
  }

  .acc-aff {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 5.89%, rgba(255, 255, 255, 1) 40.32%, rgba(0, 89, 135, 0.8) 143.6%);
    height: 752px;
    width: 100%;
    background-size: cover;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 52px;

    @media screen and (max-width: $medium) {
      height: 464px;
      gap: 12px;
    }

    h1 {
      font-family: $font-family-satoshi;
      font-size: clamp(28px, 4vw, 32px);
      font-weight: 700;
    }
  }
  .acc-aff-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 60px;

    @media screen and (max-width: $medium) {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    img {
      max-width: 200px;
      width: 100%;
      border-radius: 100%;
      box-shadow: 0px 16.67px 34.29px 0px rgba(0, 0, 0, 0.24);
      box-shadow: 0px 4.76px 9.52px 0px rgba(0, 0, 0, 0.2);

      @media screen and (max-width: $medium) {
        max-width: 100px;
      }
    }
  }
}

.scroller-container {
  padding: 150px 0 200px 0;

  @media screen and (max-width: $medium) {
    padding: 80px 0 80px 0;
  }
  h2.scroller-text {
    font-family: $font-family-satoshi;
    font-size: clamp(28px, 4vw, 34px);
    width: 70%;

    @media screen and (max-width: $medium) {
      text-align: center;
      width: 100%;
      line-height: normal;
      margin-bottom: 1rem;
    }
  }
}

// promo card
.img-card img {
  height: 350px !important;
  width: 286px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 16px;
}
// travel guides

.image {
  object-fit: contain;
}

.image img {
  height: 276px;
  width: 422px;
  border-radius: 16px;
  object-fit: cover;
}

// Media Queries

@media screen and (max-width: 940px) {
  .image img {
    height: auto;
    width: auto;
    border-radius: 16px;
    object-fit: cover;
  }
  .adventure {
    padding: 100px 10px;

    .promo-container {
      .promo-grid {
        .promo-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 14px;
        }
      }
    }
  }

  #cta {
    background-color: $primary;
    color: $whitesmoke-10;
    text-align: center;

    h1 {
      font-size: $font-size-28;
      font-weight: $font-weight-bold;
      line-height: 34px;
    }
  }
  .travel-guides {
    padding: 100px 10px;

    @media screen and (max-width: $medium) {
      padding: 50px 0 !important;
    }
    .travel-container {
      .travel-column {
        display: flex;
        flex-direction: column;
        align-items: center;

        .travel-guides-card {
          display: flex;
          flex-direction: column;
          object-fit: cover;
          padding: 10px;

          .image img {
            aspect-ratio: 3 / 2;
            object-fit: cover;
            flex: 1 1 300px;
          }

          .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: start;
            gap: 24px;
          }
          h1 {
            font-size: $font-size-18;
            font-weight: $font-weight-bold;
          }
          p {
            padding: 0;
            margin: 0;
            font-size: $font-size-16;
          }
          p:nth-last-child(1) {
            color: $secondary;
            font-size: $font-size-16;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .adventure {
    .promo-container {
      .promo-title {
        margin-bottom: 34px;

        h1 {
          font-size: $font-size-18;
        }
        h2 {
          font-size: $font-size-16;
        }
      }

      .promo-info {
        h2 {
          font-size: $font-size-18;
        }
        p {
          font-size: $font-size-16;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .adventure {
    .promo-container {
      .promo-title {
        margin-bottom: 124px;

        h1 {
          font-size: $font-size-34;
        }
        h2 {
          font-size: $font-size-24;
        }
      }

      .promo-info {
        h2 {
          font-size: $font-size-22;
        }
        p {
          font-size: $font-size-17;
        }
      }
    }
  }
}
