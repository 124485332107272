$small: 480px;
$medium: 768px;
$large: 1024px;

#passenger-details {
  #passenger-details-title {
    > span {
      font-size: 34px;
      font-family: 'Satoshi';
      font-weight: 700;
    }
  }
}

.web-details {
  max-width: 650px;
  width: 100%;
  padding: 48px;

  @media screen and (max-width: $small) {
    padding: 20px;
  }
  @media screen and (max-width: $medium) {
    padding: 20px;
  }
}

.galagoButton {
  @media screen and (max-width: $small) {
    display: flex;
    font-size: 13px;
  }
  @media screen and (max-width: $medium) {
    display: flex;
  }
  @media screen and (max-width: $large) {
    display: flex;
  }
}

// General Styles
.tealtext {
  color: #006FA9;
}
p {
  margin: 0;
}
.time {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
.footnote {
  color: #adadad;
  font-weight: 500;
}

.bookingCard {
  border: 1px solid #006FA9;
  border-radius: 16px;
  margin-bottom: 32px;
  box-shadow: 0px 4px 40px 0px rgba(51, 51, 51, 0.1);


  .card-body{
    padding: 30px 16px;
  }
}

.bookingText {
  font-size: 16px;
  font-weight: 500;
}

.flightDurationLine {
  border-top: 1px dashed #adadad;
  width: 100%;
}

.bookingPrice {
  background-color: #006FA9;
  padding: 20px 20px;
  border: 1px solid #006FA9;
  border-radius: 0 0 16px 16px;
}

// .booking-details-container {
// }

.booking-summary-sticky {
  max-width: 505px;
  width: 100%;
  @media screen and (max-width: $small) {
    margin-top: 20px;
  }
}

//! Booking Details Styles

.booking-details-flights {
  width: 100%;
  max-width: 570px;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  padding: 32px;

  .cityName {
    font-size: 34px;
    font-weight: 700;
    color: #000;
  }

  .airport-name {
    text-align: start;

    p {
      font-size: 16px;
      font-weight: 500;
      color: #adadad;
    }
  }
  .departure-arrival-date {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid #adadad;
  }
  .flightAirline {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flightDuration {
    display: flex;
    justify-content: space-between;
  }
}

//! Passenger Details
.passenger-details {
  max-width: 570px;
  width: 100%;
  padding: 0;
}

.border {
  border: 1px solid #adadad;
}
//! Contact Details

.contact-details {
  max-width: 570px;
  width: 100%;
  padding: 0;
}

.contact-title {
  font-size: 34px;
  font-weight: 700;
}

.button-checkbox {
  max-width: 570px;
  width: 100%;
  padding: 0;
}

//! booking summary

.card {
  max-width: 505px;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;

  .booking-summary-title {
    border-bottom: 1px solid #adadad;
  }

  .destination {
    display: flex;
    align-items: center;
    gap: 5px;

    p {
      padding: 0;
      margin: 0;
    }
  }
}

//! Booking Confirmed

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
  @media screen and (max-width: $small) {
    gap: 0;
  }
}

.bookingConfirmed {
  max-width: 601px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  span {
    width: 42px;
    height: 42px;
    background-color: green;
    display: grid;
    place-items: center;
    border-radius: 50%;
  }

  .confirmed {
    font-weight: 700;
    font-size: 34px;
  }

  .note {
    text-align: center;
    max-width: 370px;
    font-size: 16px;
  }
  .back {
    width: 100%;
    background-color: #006FA9;
    color: #fff;
    padding: 25px 24px;
  }
}

.bookingConfirmedSummary {
  max-width: 537px;
  width: 100%;
  border: 1px solid #006FA9;
  border-radius: 16px;
  padding: 24px;
}

.paid {
  p {
    font-size: 17px;
    font-weight: 500;
  }
  a {
    color: #006FA9;
  }
}

.price {
  font-size: 20px;
  font-weight: 500;
}



// Flight Booking Flight Verification Animation
.booking-verification-container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #fff;
  overflow: hidden;
  padding: 60px 64px;
  border-radius: 24px;
}
