#confirm-email-alert {
  > div:nth-child(1) {
    display: flex;
    padding: 24px 24px 0 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }

  > div:nth-child(2) {
    display: flex;
    padding: 0 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    text-align: center;
    gap: 10px;
    color: #333;

    h2 {
      margin: 0;
      font-size: 24px;
      font-family: 'Satoshi', sans-serif;
      font-weight: 600;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      font-family: 'General-Sans', sans-serif;
      line-height: 20px;
    }
  }

  > div:nth-child(3) {
    display: flex;
    padding: 32px 100px 42px 100px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    flex-direction: column;
  }
}
