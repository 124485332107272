/* background Color */

.dark {
  background: #222;
}

.white {
  background: whitesmoke;
}

/** Background Color **/

.overflow,
.circle-line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.overflow {
  // z-index: 9998;
  width: 100vw;
  height: 100vh;
}

.circle-line {
  // z-index: 9999;
  width: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.circle-red,
.circle-green,
.circle-yellow,
.circle-blue {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 30px;
  margin: 5px;
}

.circle-red {
  animation: movingUp 0.4s infinite alternate;
}

.circle-blue {
  animation: movingUp 0.4s 0.1s infinite alternate;
}

.circle-green {
  animation: movingUp 0.4s 0.4s infinite alternate;
}

.circle-yellow {
  animation: movingUp 0.4s 0.6s infinite alternate;
}

/* CSS ANIMATION */

@keyframes movingUp {
  from {
    top: 0px;
  }

  to {
    top: -10px;
  }
}
