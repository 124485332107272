@import './_variables.module.scss';

.header {
  position: fixed;
  min-height: 90px;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
}
ul {
  margin-bottom: 0 !important;
  list-style: none;
}

.profile-img-cont {
  width: 40px;
  border-radius: 50%;
}
.profile-img {
  width: 20px;
  border-radius: 50%;
  @media screen and (max-width: $large) {
    display: none;
  }
}
.dropdown-menu {
  right: 20px;
}
.nav-item span {
  @media screen and (max-width: $large) {
    display: none;
  }
}
.header-bg {
  background-color: $primary;
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1450px;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}
.button {
  margin-left: 15px;
  border-radius: 8px;
  color: $whitesmoke-30;
  background-color: $primary;
  padding: 8px 20px;
  font-size: $font-size-16;
}
.button-white {
  margin-left: 15px;
  border-radius: 8px;
  color: $primary;
  background-color: $whitesmoke-30;
  padding: 8px 20px;
  font-size: $font-size-16;
}

.header .nav-menu a {
  color: $whitesmoke-30;
}
.header .navbar img {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

.dropdown-menu {
  margin-top: 10px;
  width: fit-content;

  li,
  a {
    font-family: $font-family-general-sans;
    color: $charcoal-60 !important;
    font-weight: $font-weight-regular;
    :hover {
      color: $primary !important;
    }
    :focus {
      background-color: inherit !important;
    }
  }

  .dropdown-item {
    padding: 10px 16px !important;
  }
  hr {
    margin: 10px 16px !important;
    color: $whitesmoke-90 !important;
  }
}

.header .nav-menu {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 42px;
}

.header .nav-item {
  font-weight: $font-weight-regular;
  transition: 2s ease-in;
  font-family: $font-family-general-sans;
  letter-spacing: 0.5px;
}

.navbar-active {
  transition: 0.3s ease-in;
  border-bottom: 2px solid $whitesmoke-10;
  padding-bottom: 5px;
  font-weight: $font-weight-regular;
  font-family: $font-family-general-sans;
}

// .header .nav-item a:hover {
//   padding-bottom: 1px;
//   border-bottom: 1.5px solid white;
//   transition: 0.1s ease-out;
//   transform: scaleX();
//   transform-origin: right;
// }

.hamburger {
  display: none;
}

@media screen and (max-width: 1080px) {
  .header {
    max-width: 100%;
    background-color: $primary;
  }

  .header .navbar {
    max-width: 100%;
  }

  .hamburger {
    display: block;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 90px;
    flex-direction: column;
    background-color: $primary;
    width: 100%;
    height: 42vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s ease-in-out;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 0.3rem 0;
  }

  .header .navbar img {
    width: 150px;
  }
}
@media screen and (max-width: 1540px) {
  .profile-img-cont {
    padding-right: 7rem;
  }
}
@media screen and (max-width: 1670px) {
  .nav-menu ul {
    padding-right: 7rem;
  }
}

// profile photo 
.image-navbar{
  img{
    border-radius: 50%;
    margin: 0 !important;
    width: 33px !important;
    height: 33px;
  }
    .image {
      border: 2px solid #4fa2b4;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background-color: #006FA9;
      position: relative;
      display: flex;
  
      .initial {
        display: flex;
        color: white;
        font-size: 17px;
        margin: auto;
        font-family: 'Satoshi';
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }