@import './_variables.module.scss';
// }

.divider {
  padding: 2rem;
  background-color: $primary-teal-50;
}

.web-only-edit {
  @media screen and (max-width: $small) {
    display: none;
  }
  @media screen and (max-width: $medium) {
    display: none;
  }
  @media screen and (max-width: $large) {
    display: none;
  }
}
.mobile-only-edit {
  @media screen and (max-width: $small) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: $medium) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (min-width: $large) {
    display: none;
  }
}

// mobile edit drop

.mobile-trips-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.edit_wrapper {
  @media screen and (max-width: $small) {
    display: none;
  }
  @media screen and (min-width: $medium) {
    position: relative;
    z-index: 9;
    top: -20px;
  }
}
.divider {
  @media screen and (max-width: $small) {
    display: none;
  }
  @media screen and (max-width: $medium) {
    display: none;
  }
  @media screen and (min-width: $large) {
    display: none;
  }
}

.wrapper-mobile {
  @media screen and (max-width: $medium) {
    position: absolute;
    padding: 30px;
  }
  @media screen and (max-width: $small) {
    background-color: $primary-teal-50;
  }
}
.wrapper-mobile {
  @media screen and (max-width: $large) {
    position: relative;
  }
}
.teal {
  @media screen and (min-width: $medium) {
    background-color: $primary-teal-50;
    margin-bottom: 40px;
  }
}
.mobile-edit {
  @media screen and (max-width: $small) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (min-width: $medium) {
    display: none;
  }
  @media screen and (min-width: $large) {
    display: none;
  }
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  background-color: $whitesmoke-10;
  border-radius: 16px;

  .upper-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .destinations {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      strong {
        padding: 0;
        margin: 0;
        color: $primary-teal-50;
        font-weight: $font-weight-semibold;
        font-size: $font-size-16;
      }
    }
    .edit-button {
      padding: 2px 18px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      font-size: $font-size-12;
      background-color: $primary-teal-50;
      color: #fff;
    }
  }
  .lower-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .date-container {
      border-right: 1px solid $secondary;
      padding-right: 10px;
      font-size: $font-size-13;
      font-weight: $font-weight-semibold;
    }

    .passenger-count {
      font-size: $font-size-13;
      font-weight: 500;
      padding: 0 10px 0;
    }
  }
}
.edit_wrapper {
  @media screen and (max-width: $medium) {
    display: none;
  }
}

.result-select-web {
  @media screen and (max-width: $small) {
    display: none;
  }
  @media screen and (max-width: $medium) {
    display: none;
  }
  @media screen and (min-width: $large) {
    display: flex;
  }
}

.selected-mobile-card {
  @media screen and (max-width: $small) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: $medium) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (min-width: $large) {
    display: none;
  }
  @media screen and (min-width: $medium) {
    display: none;
  }

  border: 1px solid $primary-teal-50;
  border-radius: 14px;
  padding: 16px;

  .lugar {
    p {
      padding: 0;
      margin: 0;
      font-size: 11px;
    }
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .flight-date-time {
    display: flex;
    gap: 10px;

    p,
    span {
      padding: 0;
      margin: 0;
      font-size: $font-size-10;
    }
  }
  .name-duration {
    display: flex;
    gap: 10px;
    color: $secondary;
    p,
    span {
      padding: 0;
      margin: 0;
      font-size: $font-size-10;
    }
  }

  .total-wrapper {
    display: flex;
    justify-content: space-between;

    p {
      padding: 0;
      margin: 0;
    }

    .fare {
      font-size: $font-size-10;
    }
    .total-fare {
      font-size: $font-size-14;
      font-weight: $font-weight-semibold;
      color: $primary-teal-50;
    }
  }
}

// booking details

.web-booking-details {
  @media screen and (max-width: $small) {
    display: none;
  }
  @media screen and (max-width: $medium) {
    display: none;
  }
  @media screen and (max-width: $large) {
    display: none;
  }
}

//  Old CSS
#absolute-flight-info {
  width: 100%;
  background: $whitesmoke-10;
  border-radius: 12px;
  background: var(--color-shades-white-800, $whitesmoke-10);
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.09);

  > div:first-child {
    padding: 24px 50px !important;
    // gap: 14px;

    .flight-type-info {
      width: 15%;
    }

    .departure-info,
    .arrival-info {
      .d-block {
        font-family: 'General-sans', sans-serif;
        color: $charcoal-60;
        font-weight: $font-weight-medium;
      }
      span:last-child {
        font-size: $font-size-14;
      }
    }

    .passenger-info {
      width: 15%;
    }

    > div {
      > span {
        margin: 4px 0px;
      }
    }

    > div:last-child {
      padding-right: 0;
    }

    .text-secondary {
      color: $secondary;
      font-family: $font-family-general-sans;
      font-size: $font-size-16;
      font-weight: $font-weight-medium;
    }

    .capitalize {
      color: $charcoal-60;
      font-family: $font-family-general-sans;
      font-weight: 500;
    }
  }

  // > div:last-child {
  // }
}

.flight-title {
  p.text-secondary {
    font-size: 16px;
    font-family: 'General-sans', sans-serif;
    font-weight: 500;
  }

  .flight-section-label {
    > p {
      font-size: 22px;
      color: $charcoal-60;
      svg {
        margin-right: 10px;
      }
      > span {
        color: $secondary-cyan-50;
      }
    }
  }
}

#filters-section {
  // padding: 12px 30px;
  border-radius: 12px;
  background: var(--color-shades-white-800, $whitesmoke-10);
  box-shadow: 0px 1px 3px 1px rgba(60, 64, 67, 0.15), 0px 1px 2px 0px rgba(60, 64, 67, 0.3);

  #filter-types {
    .filter {
      cursor: pointer;
    }
  }
}

.filter-options {
  display: flex;
  padding: 14px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  position: absolute;
  background-color: $whitesmoke-10;
  border-radius: 4px;
  background: var(--color-shades-white-800, $whitesmoke-10);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.24);
  z-index: 10;

  > div {
    font-size: $font-size-14;

    > label {
      display: unset;
    }
  }
}

.trial-sticky {
  background-color: $whitesmoke-10;
  position: sticky;
  top: 0;
  padding-top: 1rem;
  // padding-bottom: 1rem;
  z-index: 5;
}

.trial-sticky::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: -2;
  opacity: 0;
  transition: opacity 0.3s;
}

.trial-sticky.scrolled::after {
  opacity: 1;
}

.flight-result-list {
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex: 1 0 0;

  .flight-result-border {
    // overflow: hidden;
    border-radius: 16px;
    border: 1px solid $secondary;
    // padding: 4px 30px;
    background-color: $whitesmoke-10;
    width: 100%;
    margin: 0;

    @media screen and (max-width: $small) {
      display: none;
    }
    @media screen and (max-width: $medium) {
      display: none;
    }

    // Flight Card Bundle Style
    .flight-bundle-container {
      display: flex;
      flex-direction: column;
      background: $whitesmoke-10;
      padding: 32px;
      gap: 24px;
      flex-grow: 1;
      min-height: 232px;
      color: $charcoal-60;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;

      .departure-flight,
      .return-flight {
        display: flex;
        flex-direction: row;
        gap: 64px;
        justify-content: space-evenly;
        align-items: center;

        .airline-logo-container {
          display: flex;
          flex-direction: column;
          gap: 6px;
          justify-content: center;
          width: 124px;

          .image-container {
            width: 42px;
            height: 42px;
            border-radius: 100%;
            overflow: hidden;
            margin: 0 auto;
            border: 2px solid #b0d2d7;
          }

          > p {
            font-family: $font-family-general-sans;
            font-size: $font-size-13;
            font-weight: $font-weight-medium;
            text-align: center;
          }
        }

        .departure-location,
        .arrival-location {
          display: flex;
          flex-direction: column;
          gap: 6px;
          height: fit-content;

          .data-head-text {
            font-family: $font-family-general-sans;
            font-size: $font-size-18;
            font-weight: $font-weight-semibold;
          }
          .data-text {
            font-family: $font-family-general-sans;
            font-size: $font-size-16;
            font-weight: $font-weight-semibold;
            white-space: nowrap;
          }
        }

        .duration-stops {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .data-text {
            font-family: $font-family-general-sans;
            font-size: $font-size-13;
            font-weight: $font-weight-medium;
            text-align: center;
          }

          .data-head-text {
            font-size: $font-size-13;
            font-weight: $font-weight-medium;
            text-align: center;
            color: $primary-teal-50;
            font-family: $font-family-general-sans;
          }
        }
      }
    }

    .flight-price-info {
      width: 356px;
      min-height: 232px;
      justify-content: space-evenly;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }

  .flight-result-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $whitesmoke-10;
    flex-grow: 1;
    width: fit-content !important;

    > div {
      display: flex;
      padding: 32px;
      justify-content: flex-start;
      gap: 64px;
      align-items: center;
      // flex: 1 0 0;
      font-family: 'General Sans';

      > span:first-child {
        color: #808080;
        font-size: 16px;
        font-weight: 400;
      }

      > span {
        font-weight: 500;
        font-size: 18px;
      }
    }

    .airline-logo-holder {
      width: 124px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      flex-direction: column;

      > p {
        font-weight: 500;
        color: #333;
        text-align: center;
      }
      .image-container {
        border: 2px solid #b0d2d7;
      }
    }

    .airline-info {
      width: 130px;
      .flight-num-text {
        color: $charcoal-60;
        font-size: $font-size-16;
        font-weight: $font-weight-medium;
      }
      .data-text {
        color: $charcoal-60;
        font-size: $font-size-16;
        font-weight: $font-weight-medium;
      }
      .data-head-text {
        font-size: $font-size-13;
        font-weight: $font-weight-medium;
      }
    }

    .departure-location {
      width: 124px;
      .data-text {
        color: $charcoal-60;
        font-size: $font-size-16;
        font-weight: $font-weight-medium;
        display: flex;
        flex-direction: row;
        gap: 6px;
        white-space: nowrap;
      }
      .data-head-text {
        color: $charcoal-60;
        font-size: $font-size-18;
        font-weight: $font-weight-semibold;
        text-align: right;
      }
    }

    .arrival-location {
      width: 124px;
      .data-text {
        color: $charcoal-60;
        font-size: $font-size-16;
        font-weight: $font-weight-medium;
        display: flex;
        flex-direction: row;
        gap: 6px;
        white-space: nowrap;
      }
      .data-head-text {
        color: $charcoal-60;
        font-size: $font-size-18;
        font-weight: $font-weight-medium;
        text-align: left;
      }
    }

    .duration-stops {
      width: 124px;
      gap: 4px !important;
      .data-text {
        color: $charcoal-60;
        font-size: $font-size-13;
        font-weight: $font-weight-medium;
        text-align: center;
      }
      .data-head-text {
        font-size: $font-size-13;
        font-weight: $font-weight-medium;
        text-align: center;
        color: $primary-teal-50;
      }
    }

    .departure-location,
    .arrival-location,
    .airline-info,
    .duration-stops {
      display: flex;
      // width: 72px;
      padding: 12px 10px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 6px;
    }
  }

  // Flight Price
  .flight-price-info {
    padding: 32px;
    display: flex;
    flex-direction: column;
    background-color: $whitesmoke-10;
    height: 100%;
    justify-content: flex-start;
    align-content: flex-start;
    justify-content: space-evenly;
    position: relative;

    .inclusion-section {
      cursor: pointer;
      background-color: $whitesmoke-10;
      border-radius: 6px;
      padding: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      width: fit-content;
      border: 2px solid $whitesmoke-10;

      .included {
        color: $primary-teal-50;
        font-weight: $font-weight-medium;
        font-family: $font-family-satoshi;
        font-size: $font-size-13;
        margin-top: 0.2rem;
      }
    }

    .text-price {
      font-size: $font-size-20;
      font-weight: $font-weight-bold;
    }

    .baggage-tooltip {
      position: absolute;
      z-index: 1;
      top: 40%;
      display: flex;
      flex-direction: column;
      gap: 14px;
      background-color: $whitesmoke-20;
      border: 2px solid $secondary-cyan-20;
      border-radius: 10px;
      width: 100%;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
      padding: 24px;
      > div {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        > span {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          height: 42px;
          width: 42px;
          background: $whitesmoke-10;
          border: 2px solid $secondary-cyan-20;
        }
        > p {
          color: $charcoal-60;
          font-weight: $font-weight-medium;
          font-size: $font-size-14;
          font-family: $font-family-general-sans;

          > span {
            color: $primary-teal-50;
          }
        }
      }
    }
  }

  // mobile
  .oneway-result-item {
    @media screen and (max-width: $small) {
      display: flex;
      flex-direction: column;
    }
    @media screen and (min-width: $medium) {
      display: none;
    }
    @media screen and (min-width: $large) {
      display: none;
    }
    display: flex;
    .oneway-card {
      width: 100%;
      background-color: #ffffff;
      border-radius: 16px;
      padding: 12px;

      .dashed {
        border: 0.5px dashed;
      }
      #airline-details {
        display: flex;
        gap: 6px;
        align-items: center;

        p {
          padding: 0;
          margin: 0;
          font-weight: 500;
          font-size: 11px;
        }

        .airlogo {
          height: 24px;
          width: 24px;
          border-radius: 50%;
          background-size: cover;
          overflow: hidden;
        }
      }
      #flight-details {
        p {
          padding: 0;
          margin: 0;
        }

        #trip-type,
        #duration {
          color: var(--Black-Black-100, #333);
          text-align: center;
          font-family: 'General Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        #from,
        #to {
          color: var(--text-colors-text-primary, #333);
          font-family: Satoshi;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 15.6px */
        }
        #time {
          color: var(--text-colors-text-primary, #333);
          font-family: 'General Sans';
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 130%; /* 31.2px */
        }
        #day {
          color: #adadad;
          font-family: 'General Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 15.6px */
        }

        // OLD CODE
        // .first-Col {
        //   #from {
        //     color: var(--text-colors-text-primary, #333);
        //     font-family: Satoshi;
        //     font-size: 12px;
        //     font-style: normal;
        //     font-weight: 500;
        //     line-height: 130%; /* 15.6px */
        //   }
        //   #time {
        //     color: var(--text-colors-text-primary, #333);
        //     font-family: 'General Sans';
        //     font-size: 24px;
        //     font-style: normal;
        //     font-weight: 600;
        //     line-height: 130%; /* 31.2px */
        //   }
        //   #day {
        //     color: #adadad;
        //     font-family: 'General Sans';
        //     font-size: 12px;
        //     font-style: normal;
        //     font-weight: 500;
        //     line-height: 130%; /* 15.6px */
        //   }
        // }

        // .second-col {
        // }
        // .third-col {
        //   #from {
        //     color: var(--text-colors-text-primary, #333);
        //     font-family: Satoshi;
        //     font-size: 12px;
        //     font-style: normal;
        //     font-weight: 500;
        //     line-height: 130%; /* 15.6px */
        //   }
        //   #time {
        //     color: var(--text-colors-text-primary, #333);
        //     font-family: 'General Sans';
        //     font-size: 24px;
        //     font-style: normal;
        //     font-weight: 600;
        //     line-height: 130%; /* 31.2px */
        //   }
        //   #day {
        //     color: #adadad;
        //     font-family: 'General Sans';
        //     font-size: 12px;
        //     font-style: normal;
        //     font-weight: 500;
        //     line-height: 130%; /* 15.6px */
        //   }
        // }
      }
      #baggage-total {
        display: flex;
        justify-content: space-between;
        p {
          margin: 0;
          padding: 0;
          color: var(--Primary-Primary-100, $primary-teal-50);
          text-align: right;
          font-family: 'General Sans';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 131.5%; /* 21.04px */
        }
      }
    }
  }
}

// One Way Selected Card
.selected-departure-arrival-info {
  position: relative;
  margin-top: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 12px 12px 12px;
  border: 1px solid var(--primary-primary-100, $primary-teal-50);
  background: var(--color-shades-white-800, $whitesmoke-10);
  margin-bottom: 56px;
  color: $charcoal-60;

  > span#selected-flight-floating-label {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    z-index: 0;
    gap: 5px;
    padding: 5px 20px;
    border-radius: 8px 8px 0px 0px;
    background: var(--primary-primary-100, #006FA9);
    left: -1px;
    top: -32px;
  }

  

  // One Way Selected Flight Info
  .one-way-flight-info-container {
    display: flex;
    flex-direction: row;
    gap: 32px;
    width: fit-content;
    align-items: center;
    padding: 64px 32px;
    min-height: 220px;

    .airline-logo-holder {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 200px;
      justify-content: center;
      height: fit-content;

      > img {
        width: 64px;
        height: auto;
        border: 2px solid $secondary-cyan-30;
        border-radius: 50%;
        margin: 0 auto;
      }

      .airline-name {
        font-size: $font-size-14;
        text-align: center;
        font-family: $font-family-general-sans;
        font-weight: $font-weight-medium;
      }
    }

    .flight-info {
      min-width: 500px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .data-head-text {
        font-size: $font-size-24;
        font-family: $font-family-general-sans;
        font-weight: $font-weight-semibold;
        line-height: 28px;
      }

      .flight-details {
        display: flex;
        flex-direction: row;
        gap: 0px;

        > hr {
          width: 18px;
          align-self: stretch;
          background-color: #d9d9d9;
          rotate: 90deg;
          margin: auto 0;
        }

        > span {
          font-family: $font-family-general-sans;
          font-size: $font-size-16;
          font-weight: $font-weight-medium;
          line-height: 20px;
        }
      }

      .flight-duration,
      .stops {
        font-size: $font-size-13;
        font-family: $font-family-general-sans;
        font-weight: $font-weight-medium;
        text-align: center;
      }

      .stops {
        color: $primary-teal-50
      }
    }

    .flight-number {
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: $charcoal-60;
      min-width: 200px;
      justify-content: center;
      padding: 0px 68px;

      > span:nth-child(1) {
        font-size: $font-size-20;
        font-family: $font-family-general-sans;
        font-weight: $font-weight-semibold;
        line-height: 28px;
      }

      > span:nth-child(2) {
        font-size: $font-size-14;
        font-family: $font-family-general-sans;
        font-weight: $font-weight-medium;
        line-height: 20px;
        white-space: nowrap;
      }
    }
  }

  //  Round-Trip Selected Flight Info
  .round-trip-flight-info-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: fit-content;
    align-items: center;
    padding: 64px 32px;
    min-height: 344px;

    > div {
      display: flex;
      flex-direction: row;
      gap: 32px;
      width: fit-content;
      align-items: center;

      .airline-logo-holder {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 200px;
        justify-content: center;
        height: fit-content;

        > img {
          width: 64px;
          height: auto;
          border: 2px solid $secondary-cyan-20;
          border-radius: 50%;
          margin: 0 auto;
        }

        .airline-name {
          font-size: $font-size-14;
          font-family: $font-family-general-sans;
          font-weight: $font-weight-medium;
          text-align: center;
        }
      }

      .flight-info {
        min-width: 500px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > div {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .data-head-text {
          font-size: $font-size-24;
          font-family: $font-family-general-sans;
          font-weight: $font-weight-semibold;
          line-height: 28px;
        }

        .flight-details {
          display: flex;
          flex-direction: row;
          gap: 0px;

          > hr {
            width: 18px;
            align-self: stretch;
            background-color: #d9d9d9;
            rotate: 90deg;
            margin: auto 0;
          }

          > span {
            font-family: $font-family-general-sans;
            font-size: $font-size-16;
            font-weight: $font-weight-medium;
            line-height: 20px;
          }
        }

        .flight-duration,
        .stops {
          font-size: $font-size-13;
          font-family: $font-family-general-sans;
          font-weight: $font-weight-medium;
          text-align: center;
        }

        .stops {
          color: $primary-teal-50;
        }
      }

      .flight-number {
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: $charcoal-60;
        min-width: 200px;
        justify-content: center;
        padding: 0px 68px;

        > span:nth-child(1) {
          font-size: $font-size-20;
          font-family: $font-family-general-sans;
          font-weight: $font-weight-semibold;
          line-height: 28px;
        }

        > span:nth-child(2) {
          font-size: $font-size-14;
          font-family: $font-family-general-sans;
          font-weight: $font-weight-medium;
          line-height: 20px;
          white-space: nowrap;
        }
      }
    }
  }

  .price-container {
    display: flex;
    min-height: 220px;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 24px 64px;
    background: #F5FBF7;
    width: 100%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    .baggage-container {
      display: flex;
      flex-direction: row;
      background: $whitesmoke-10;
      border-radius: 6px;
      gap: 6px;
      width: fit-content;
      padding: 4px 10px;
      align-items: center;

      p {
        color: $primary-teal-50;
        margin: 0;
        font-size: $font-size-14;
        font-weight: $font-weight-medium;
        line-height: 20px;
        font-family: $font-family-general-sans;
        margin-top: 4px;
      }
    }

    span {
      font-size: $font-size-14;
      font-weight: $font-weight-bold;
      line-height: 20px;
    }
  }
}
