@import './_variables.module.scss';

#email-modal-header,
#password-modal-header {
  display: flex;
  padding: 34px 32px 0px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 8px;

  p {
    font-size: 22px;
    font-family: 'Satoshi', sans-serif;
  }

  h4 {
    color: #333;
    margin: 0;
  }

  > span {
    color: #808080;
    font-size: 14px;
    font-family: 'General-Sans', sans-serif;
    font-weight: 500;
  }
}

#email-modal-body,
#password-modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px 0px 34px 0px;
  justify-content: center;
  gap: 10px;
  align-items: center;
  align-self: stretch;

  > .floating-label,
  > input,
  > button {
    display: flex;
    width: 343px !important;
    height: 56px;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  #email-error-message,
  #password-error-message {
    font-family: 'General-Sans', sans-serif !important;
    font-size: 14px;
    color: $error;
    gap: 8px;
  }

  #email-error-message,
  #password-error-message {
    display: flex;
    width: 100%;
    padding-left: 104px;
    align-items: center;
  }
}
