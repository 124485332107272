.margin-bottom:last-child {
  margin-bottom: 1rem;
}

.hotel-card-price {
  padding-right: 2rem;
  display: grid;
  grid-template-columns: 70% 35%;


  div {
    width: 100%
  }
}

