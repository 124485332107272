$small: 480px;
$medium: 768px;
$large: 1024px;

#async-hotel {
  background: #fff;
  width: auto !important;
  padding: 0 !important;
  margin-top: 10px;
  box-shadow: 0px 1px 2px 0px rgba(60, 64, 67, 0.3), 0px 2px 6px 2px rgba(60, 64, 67, 0.15);
  min-width: 520px !important;
  overflow-x: hidden !important;

  > a {
    border-bottom: 1px solid #d9d9d9;

    &:last-child {
      border-bottom: unset;
    }

    &:hover {
      background: #e6f1f3;
    }

    > div {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  @media screen and (max-width: $medium) {
    box-shadow: none;
    width: 100% !important;

  }
}



input[aria-owns='async-hotel'],
.rbt-input-main {
  padding-left: 32px;
  padding-right: 28px;
}

#hotel-destination-holder {
  > svg {
    position: absolute !important;
    z-index: 1;
  }

  > svg.clear-text-svg {
    bottom: 17px;
    right: 10px;
    cursor: pointer;
  }

  > svg.location-svg {
    bottom: 17px;
    left: 10px;
  }
  ::placeholder {
    color: #a8a8a8;
  }
}

.hotel-check-in-date {
  position: absolute;
  top: 32px;
}

.hotel-calendar-holder {
  position: absolute;
  z-index: 1;
  width: 350px;
}

// .hotel-mobile {
//   display: flex;
//   justify-content: space-between;
//   gap: 12px;
//   align-items: baseline;

//   @media screen and (max-width: $medium) {
//     flex-direction: column;
//     margin-top: 20px;
//   }
// }

.hotel-content {
  display: flex;
  gap: 8px;

  @media screen and (max-width: $medium) {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem 0;
  }
}

.destination {
  max-width: 343px;
  width: 100%;
  @media screen and (max-width: $medium) {
    max-width: $medium;
  }
}

.date-input {
  max-width: 320px !important;
  width: 100%;

  @media screen and (max-width: $medium) {
    max-width: $medium !important;
  }
}

.guests-rooms{
  max-width: 320px;
  width: 100%;
  position: relative;
  @media screen and (max-width: $medium) {
    max-width: $medium;
  }
}
.search-btn {
  display: flex;
  align-items: last baseline;
  max-width: 200px;
  width: 100%;

  @media screen and (max-width: $medium) {
    max-width: $medium;
    margin-top: 0.5rem;
  }
}

.location-alert-container {
  background-color: rgb(232, 235, 235);
  padding: 32px 40px;
  border-radius: 12px;

  .location-alert {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;

    .text-heading {
      font-size: 17px;
      font-weight: 600;
      font-family: General Sans;
    }
    .text-alert {
      font-size: 13px;
      font-weight: 500;
      font-family: General Sans;
    }
    .location-alert-button {
      display: flex;

      width: 100%;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}
