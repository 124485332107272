@import './_variables.module.scss';
#floating-cart {
  position: fixed;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--border-colors-border-primary, #d9d9d9);
  background: var(--White-White-100, #fff);

  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  font-style: normal;

  .cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #006FA9;
    color: white;
    font-size: 14px;
    font-family: 'General-sans', sans-serif;
    font-weight: 500;
    padding: 10px 32px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 100%;
    p {
      margin: 0;
    }
  }

  .cart-body {
    width: 100%;
    padding: 24px 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (min-width: 620px) and (max-width: 1814px) {
    width: 78%;
  }

  @media screen and (min-width: 1815px) {
    width: 56%;
  }

  h4 {
    color: $primary;
    font-size: 20px;
    line-height: 25px;
  }

  #floating-cart-price {
    font-size: 26px;
    font-weight: 500;
    line-height: 25px;
  }
}
