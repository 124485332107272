
@import './_variables.module.scss';



.account-security {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;

  .title_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #333;
    .account-security__title {
      font-size: 22px;
      font-weight: 700;
      font-family: 'Satoshi';
    }
    .account-security__subtitle {
      font-size: 13px;
      font-weight: 500;
    }
  }

  .account-security__tabs {
    display: flex;
    flex-direction: column;
    gap: 24px;
    transition: 0.3s ease-in-out;

    .tab-title {
      color: #006FA9;
      font-size: 14px;
      font-weight: 500;
    }
    p {
      max-width: 464px;
      font-size: 14px;
      font-weight: 500;
    }

    .account-security__tab_signout {
      border-bottom: 1px solid #e7eaeb;
      padding-bottom: 20px;
      display: none;
      //   display: flex;
      flex-direction: column;
      cursor: pointer;
      gap: 4px;
    }

    .account-security__tab {
      border-bottom: 1px solid #e7eaeb;
      padding-bottom: 20px;

      cursor: pointer;
      gap: 4px;

      &:last-child {
        border: none;
      }

      .show-tab {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .tab-head {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }

        .arrow-icon {
          transition: transform 0.3s ease;
        }
        .arrow-icon.rotated {
          transform: rotate(180deg);
        }
      }
      .show-tab-password {
        margin-top: 2rem;
        display: flex;

        .tab-title {
          color: #858585;
        }

        .password-input {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .password-input__input {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
        }
        .show-tab-buttons {
          display: flex;
          gap: 1rem;
          padding-top: 32px;
          justify-content: flex-end;

          .btn-cancel {
            padding: 10px 40px;
            background-color: white;
            color: #006FA9;
            font-size: 13px;
          }
          .btn-save {
            padding: 10px 40px;
            font-size: 13px;
          }
        }
      }
      .show-tab-delete {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 32px;

        .delete-note {
          padding: 24px 42px 24px 24px;
          display: flex;
          flex-direction: column;
          gap: 22px;
          background-color: #4fa2b40d;
          width: 100%;
          color: #333;
          justify-content: center;
          border-radius: 6px;

          .gally-note {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;

            .circle-img {
              background-color: #faf0ef;
              border: 1px solid #faf0ef;
              border-radius: 50%;
              padding: 20px;

              img {
                width: 50px;
                height: 50px;
              }
            }
          }
          .note-body {
            display: flex;
            flex-direction: column;
            gap: 14px;

            .item {
              max-width: 594px;
              width: 100% !important;
            }

            .note-list {
              display: flex;
              flex-direction: column;
              gap: 10px;
              padding-left: 5px;
              .note {
                display: flex;
                flex-direction: row;
                gap: 10px;
              }
            }
          }
        }

        .reason-delete {
          .delete-buttons {
            margin-top: 32px;
            display: flex;
            gap: 1rem;
            justify-content: flex-end;

            .btn-cancel {
              padding: 10px 40px;
              background-color: white;
              color: #006FA9;
              font-size: 13px;
              font-weight: 500;
            }
            .btn-delete {
              padding: 10px 40px;
              font-size: 13px;
              font-weight: 500;
            }
          }
          .gally-warning-container {
            background-color: #f9f9f9;
            padding: 10px;
            border-radius: 8px;
            .gally-warning {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 1rem;
              img {
                width: 42px;
                height: 42px;
              }
              p {
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}
