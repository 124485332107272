.gallery-wrapper {
  // max-width: 75vw;
  width: 100%;
  display: flex;
  max-height: 55vh;
  gap: 10px;
  overflow: hidden;

  border-radius: 16px;

  .large-pic {
    display: flex;
    justify-content: center;
    object-fit: contain;
    flex: 1;

    img {
      max-height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.gallery-photo {
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  .upper {
    display: flex;
    height: 50%;
    gap: 10px;

    .upp-img {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .lower {
    display: flex;
    gap: 10px;
    height: 50%;

    .overlay {
      display: grid;
      place-items: center;
      background-color: #000000;
      opacity: 50%;
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-bottom-right-radius: 16px;
    }

    .low-img {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .dark {
      display: grid;
      place-items: center;
      position: relative;

      p {
        color: white;
        font-size: 17px;
      }
    }
  }
}

.card-img-hotel {
  max-height: 246px;
  overflow: hidden;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
