@import './_variables.module.scss';

#hero-section {
  height: 80vh;

  // background: url("http://47.245.126.170:30085/api/v1/download-shared-object/aHR0cDovLzEyNy4wLjAuMTo5MDAwL3dhbGt0aGVwbGFuZXQtYXNzZXRzL0hvbWVwYWdlLnBuZz9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPTU1VTc4NjBHWktGMVg0SDJITVMxJTJGMjAyNDEyMDQlMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjQxMjA0VDA5NDM0M1omWC1BbXotRXhwaXJlcz00MzIwMCZYLUFtei1TZWN1cml0eS1Ub2tlbj1leUpoYkdjaU9pSklVelV4TWlJc0luUjVjQ0k2SWtwWFZDSjkuZXlKaFkyTmxjM05MWlhraU9pSTFOVlUzT0RZd1IxcExSakZZTkVneVNFMVRNU0lzSW1WNGNDSTZNVGN6TXpNME9EVTBOU3dpY0dGeVpXNTBJam9pZDJGc2EzUm9aWEJzWVc1bGRDSjkuck92WFk2VEl4eGtrb3RqeUpMUkl1N2U0NkRjZnNWdW1tVTJoQUlQREMtN2NUOXBJV1dWSEJaRTBiOVJiNHRyZE4xbjUxek96V3VZUXFTS2dibXZ0WVEmWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0JnZlcnNpb25JZD1udWxsJlgtQW16LVNpZ25hdHVyZT01ZjVmMWQyNmMyZWFlMjM1ZGRiNzI0ZDYxZWQwNDFhMjYxMzk2YTFhNzJlNzg5NWY2MGQ1ZTM2MGFhMTk1NzRj");

  // video {
  //   width: 100%;
  //   height: 100vh;
  //   object-fit: cover;
  //   position: relative;
  //   opacity: 0.8;
  // }

  @media screen and (min-width: $large ) {
    height:100vh;
  }

  @media screen and (min-width: 2000px ) {
    height:80vh;
  }

  
  @media screen and (min-width: 3400px ) {
    height:50vh;
  }


  @media screen and (max-width: $medium) {
    height: 100vh;
    
  }

  .hero-background {
    height: 534px;

    @media screen and (max-width: $medium) {
      height: 100%;
    }
  }

  .bg-hotels {
    background: url('http://47.245.126.170:30085/api/v1/buckets/walktheplanet-assets/objects/download?preview=true&prefix=Homepage-Hotel.jpg&version_id=null');
    background-position: 50% 0%;
    background-size: cover;
  }

  .bg-flights {
    background: url('http://47.245.126.170:30085/api/v1/buckets/walktheplanet-assets/objects/download?preview=true&prefix=Homepage.png&version_id=null');
    background-position: 0% 30%;
    background-size: cover;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 9;
    width: 100%;
    height: 534px;
    background: linear-gradient(92.37deg, #006fa9 -11.76%, rgba(0, 111, 169, 0) 108.09%);
    // opacity: 80%;
    background-size: cover;

    @media screen and (max-width: $medium) {
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 111, 169, 0) 0%, rgba(44, 0, 169, 0.08) 56.87%, #006fa9 112.68%), linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
        linear-gradient(0deg, rgba(0, 111, 169, 0) 45.6%, rgba(0, 111, 169, 0.8) 132.96%);
    }
  }

  .content {
    position: absolute;
    top: 17.12em;
    width: 100%;
    padding: 0.2rem 5rem;
    color: $whitesmoke-60;
    display: flex;
    gap: 9.4rem;
    flex-direction: column;

    @media screen and (max-width: $medium) {
      padding: 0;
      // display: block;
    }

    @media screen and (max-width: $medium) {
      top: 8.5em;
      gap: 0rem;
    }



    .hero-text {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 0.5rem;
      @media screen and (max-width: $medium) {
        text-align: start;
        padding: 24px 24px 10px 24px;
      }
    }
    .hero-tagline {
      font-family: 'Syne', sans-serif;
      font-weight: 700;
      font-size: clamp(32px, 5vw, 40px);
    }

    .hero-subtag {
      font-family: 'General Sans', sans-serif;
      font-size: clamp(16px, 5vw, 17px);
      font-weight: 500;
    }

    .search-form-container {
      max-width: 1232px;
      width: 100%;
      align-self: center;

      @media screen and (max-width: $medium) {
        padding: 12px 24px;
      }
    }
    #search-form-main {
      align-items: center;
      box-shadow: 0px 4px 50px 0px #3333331a;
      background: $whitesmoke-10;
      background: linear-gradient(338deg, rgba(211, 211, 211, 1) 0%, rgba(211, 211, 211, 0.31817233729429273) 48%, rgba(211, 211, 211, 1) 100%, rgba(211, 211, 211, 1) 100%);
      color: black;
      border-radius: 8px;
      font-weight: $font-weight-medium;
      a {
        text-decoration: none;
      }
      .nav-tabs {
        border: none;

        .nav-item {
          margin: 0;
        }
      }

      .nav-link {
        border: none;
      }
      a {
        text-decoration: none;

        &:hover {
          color: $whitesmoke-10 !important;
        }
      }
    }

    .tab-content {
      background-color: $whitesmoke-10;
      border-radius: 0 0px 8px 8px;
    }
  }
}

.form-label {
  color: #333;
  font-family: 'Satoshi', sans-serif;
  font-size: 15px;
  font-weight: 500;
}

#flights-switch {
  display: none;
  font-family: 'General Sans', sans-serif;

  @media screen and (max-width: $medium) {
    display: flex;
    align-items: flex-start;
    border-radius: 15px;
    border: 1px solid $primary-teal-50;

    > a {
      border: unset;
    }

    > a:hover {
      background-color: $primary-teal-50;
      color: $whitesmoke-10;
    }

    > a:first-child {
      // border-right: unset;
      // border-top-right-radius: 12px;
      // border-bottom-right-radius: 12px;
    }

    > a:not(:first-child):not(:last-child) {
      border-right: unset;
      border-left: unset;
      border-radius: 12px;
    }

    > a:last-child {
      // border-left: unset;
      // border-top-left-radius: 12px;
      // border-bottom-left-radius: 12px;
    }

    .form-label {
      color: $charcoal-60;
      font-family: 'General-Sans', sans-serif;
      font-size: $font-size-13;
      font-weight: $font-weight-medium;
    }
  }
}

.search-flight-button {
  svg {
    display: -webkit-inline-box;
  }

  border: none;
  box-shadow: 1.95px 1.95px 2.6px 0px #00000026;
}

.search-flight-button:disabled {
  background-color: #adadad;
  opacity: 1;
}

.search-hotel-button {
  border: none;
  box-shadow: 1.95px 1.95px 2.6px 0px #00000026;
}

#passenger-details-holder {
  z-index: 1;
  padding: 16px;
  gap: 6px;
  border-radius: 8px;
  background: var(--color-shades-white-800, $whitesmoke-10);
  box-shadow: 0px 1px 3px 1px rgba(60, 64, 67, 0.15), 0px 1px 2px 0px rgba(60, 64, 67, 0.3);
  // width: 278px !important;

  .passenger-count-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;

    span:first-child {
      color: $charcoal-60;
      font-size: 16px;
      font-weight: $font-weight-medium;
    }
    span:last-child {
      color: #333;
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
    }
  }

  .passenger-count-btn {
    border: 1px solid transparent;
    background: $whitesmoke-20;
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }

  // details for guest and rooms for hotels
  .hotel-guest-room-info {
    margin-top: 16px;
    display: flex;
    padding: 14px 10px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    background: $secondary-cyan-10;

    > div:last-child {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: var(--text-colors-text-primary, $charcoal-60);
      font-size: 12px;
      font-style: normal;
      line-height: normal;

      > a {
        cursor: pointer;
        color: $primary-teal-50;
        font-size: $font-size-12;
        font-style: normal;
        text-decoration-line: underline;
      }
    }
  }
}

.switch-arrow-svg {
  display: inline-flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  // gap: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: $primary-teal-50;
  transition: 0.5s all ease-in-out;
}

.switch-arrow-svg:hover {
  transform: rotate(180deg);
}

.button-disabled {
  background-color: $charcoal-40 !important;
  opacity: 1 !important;
  // border-color: #fff !important;
}

#filters-holder {
  border: 2px solid $secondary-cyan-50;
  z-index: 1;
  border-radius: 8px;
  background: var(--color-shades-white-800, #fff);
  box-shadow: 0px 1px 3px 1px rgba(60, 64, 67, 0.15), 0px 1px 2px 0px rgba(60, 64, 67, 0.3);
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-color: #adadad transparent;
  padding-top: 15rem;

  .filters-opt {
    width: 100%;
    padding: 12px;
    cursor: pointer;
  }

  .filters-opt:hover {
    background-color: $secondary-cyan-10;
  }

  .filters-opt:nth-child(1) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .filters-opt:nth-child(8) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

#cabin-details-holder {
  // border: 2px solid $secondary-cyan-50;
  z-index: 1;
  border-radius: 8px;
  background: var(--color-shades-white-800, $whitesmoke-10);
  box-shadow: 0px 1px 3px 1px rgba(60, 64, 67, 0.15), 0px 1px 2px 0px rgba(60, 64, 67, 0.3);
  width: 100%;

  .cabin-opt {
    width: 100%;
    padding: 12px;
    cursor: pointer;
  }

  .cabin-opt:hover {
    background-color: $secondary-cyan-50;
  }

  .cabin-opt:nth-child(1) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .cabin-opt:nth-child(4) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
