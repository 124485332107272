.displayedTooltip {
  right: -24px;
  z-index: 999;
  margin-top: 10px;
  // background-color: #f8f9fa;

  &::after {
    content: '';
    position: absolute;
    top: -5px;
    right: 27px;
    width: 20px;
    height: 20px;
    background-color: #f8f9fa;
    rotate: -45deg;
    z-index: -1;
  }
}
#parentTooltip:hover::after {
  content: url('../svg/BaggageSvg.tsx');
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  z-index: 1;
}

#parentTooltip{
  .info {
    width: 400px;
  }
  span {
    padding: 4px;
    // font-size: 18px;
    // font-weight: 500;
    font-family: 'General Sans';
  }
  p {
    padding: 4px;
    font-size: 17px;
    font-weight: 500;
    font-family: 'General Sans';
    margin: 0;
  }
  hr {
    margin: 2px;
    padding: 2px;
  }
  p.DiscountText {
    width: 250px;
    color: #adadad;
  }
  .positive {
    color: #217c38;
  }
  .negative {
    color: red;
  }
  
}

