$small: 480px;
$medium: 768px;
$large: 1024px;

.privacy-header {
  height: 82px;
  padding: 24px 104px;
  background-color: #006FA9;
  color: #ffffff;
  div {
    p {
      font-family: 'General Sans', sans-serif;
      color: #ffffff;
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.privacy-info {
  padding: 42px 104px 74px 104px;
  @media screen and (max-width: $large) {
    padding: 42px 25px 74px 25px;
  }
}

.privacy-intro {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .intro {
    font-weight: 500;
  }
}
.heading-large-bold {
  font-size: 28px;
  font-weight: 700;
}

.privacy-text {
  display: flex;
  flex-direction: column;
  max-width: 870px;

  div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1rem;
    h1 {
      font-family: 'General Sans', sans-serif;
      font-weight: 500;
      font-size: 16px;
      color: #006FA9;
    }
    p {
      font-family: 'General Sans', sans-serif;
      font-size: 14px;
      font-weight: 500;
      text-align: justify;
      padding: 0px 10px;
      color: #333;
    }
  }
}

.privacy-content {
  display: flex;
  gap: 60px;
  justify-content: space-between;
  @media screen and (max-width: $large) {
    gap: 20px;
  }

  .privacy-group {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.privacy-intro {
  max-width: 870px;
  text-align: justify;
  font-family: 'General Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 24px;
}
.privacy-table {
  padding: 24px;
  height: fit-content;
  position: sticky;
  top: 100px;
  box-sizing: border-box;
  @media screen and (max-width: $large) {
    max-width: 300px;
    padding: 20px;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .privacy-card-title {
      font-family: 'General Sans';
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 16px;
    }
    p {
      border-radius: 12px;
      text-decoration: none;
      color: #006FA9A6;
      padding-left: 10px;
      font-family: 'General Sans', sans-serif;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        color: #006FA9;
      }
      &:visited {
        color: #006FA9;
      }
    }
  }
}
.privacy-teal {
    color: #006FA9 !important; 
}
h1{
    margin: 0;
    font-weight: 600;
    font-family: General Sans;
}