@import './_variables.module.scss';
// Overrides the default styles of form-inputs
// removing the shadow when the input text if focused
$input-focus-box-shadow: unset !important;
$form-select-focus-box-shadow: unset !important;
$form-check-input-focus-box-shadow: unset !important;

// default font family
// $input-font-family: 'GeneralSans-Medium';
// default padding
$input-padding-x: 0.75rem;
$input-padding-y: 0.75rem;
$input-border-radius: 12px;
// $input-border-color: $primary;
// $input-font-weight: 600;
$btn-padding-y: 0.75rem;
$btn-padding-x: 0.75rem;

// make the floating form control label background transparent
.form-floating {
  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-control-plaintext,
  > .form-select {
    ~ label {
      &::after {
        background-color: transparent;
      }
    }
  }
}

.floating-label {
  > label {
    color: #adadad;
    font-size: 1rem;
  }
}

.form-control.is-invalid:focus {
  box-shadow: unset !important;
}

.toggle-show-password {
  position: absolute;
  right: 20px;
  top: 20px;
  color: $primary;
  border-bottom: 1px solid $primary;
  font-size: 13px;
  cursor: pointer;
}

.galago-form-control {
  padding-left: 36px;
  font-family: 'General Sans', sans-serif;
  font-size: 14px !important;
}

.galago-form-control::placeholder {
  color: #333;
  font-size: 14px !important;
}

.form-control:invalid,
.form-control.is-invalid {
  background-image: unset;
  padding-right: 0.75em;
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'] {
  --bs-form-select-bg-icon: unset;
  padding-right: 0.75em;
}

.form-switch {
  margin: 0px;
  .form-check-input {
    width: 40px;
    height: 20px;
  }
}

.form-control {
  border-width: 2px;

  &.focus:not(#return-date-frm) {
    border: 2px solid $primary-light-active;
  }
}

.calendar-date {
  input.form-control {
    padding-left: 2rem;
  }
}

.transparent-line {
  input.form-control {
    caret-color: transparent;
    cursor: pointer;
  }
}

// .form-control:focus {
//   label {
//     color: $primary !important;
//   }
// }

// input {
//   label {
//     color: $primary !important;

//   }

//   ::placeholder {
//     color: red;
//     opacity: 1; /* Firefox */
//   }

//   ::-ms-input-placeholder {
//     /* Edge 12 -18 */
//     color: red;
//   }
// }

// .active {
//   label {
//     color: $primary !important;
//   }
// }

// input:focus-visible{
//   border-color: #4fa2b4 !important;
//   label {
//     color: $primary !important;
//   }
// }
