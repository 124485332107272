.payment-page-summary {
  display: grid;
  grid-template-columns: auto 1fr 500px;
  border-radius: 24px;
  box-shadow: 0px 2px 4px 1px #00000024;

  box-shadow: 0px 0px 2px 1px #0000001f;

  ::-webkit-scrollbar {
    height: 4px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #e7eaeb; 
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb:hover{
    background-color: #006FA9; 
    
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff; /* set the background color of the scrollbar */
    margin-bottom: 10px !important;
  }

  ::-webkit-scrollbar-button {
    width: 10px; //for horizontal scrollbar
  }

  .collage-container {
    padding: 32px 21px 32px 42px;
    background-color: #f9fafb;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }

  .booking-summary-container {
    padding: 32px 42px 32px 21px;
    background-color: #f9fafb;
  }

  .payment-page-collage {
    width: 300px;
    height: 300px;
    border-radius: 14px;
    position: relative;
    overflow: hidden;
  }

  .payment-page-collage > div {
    position: absolute;
    right: 0;
    top: 0;
    width: 125px;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    gap: 10px;
    align-items: center;

    div {
      width: 64px;
      height: 80px;
      border: 1px solid white;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}

.rooms-list {
  display: flex;
  // gap: 1rem;
  max-width: 550px;
  border: 4px solid #f6f8f8;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 10px;
  p {
    font-size: 13px;
  }

  .room {
    min-width: 235px;
    margin: 15px 30px;

  }

  .multiple-rooms:not(:last-of-type) {
    border-right: 1px solid #f9f9f9;
  }


}

.price-overview-container {
  background-color: white;
  padding: 32px 42px;
  margin: 0;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  .price-overview {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .final-room-name {
    max-width: 280px;
  }
}

.tag {
  background-color: #f2fbfb;
  padding: 4px 6px;
  border-radius: 6px;
  width: max-content;
}

.total-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e7eaeb;
}
