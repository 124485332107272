// Title and Airline (Frequent Flyter Number) Dropdown ----------------
.dropdown-option {
  width: 515px;
  // height: 200px;
  background-color: white;
  border-radius: 8px;
  border: 2px solid #4fa2b4;
  position: absolute;
  top: 110%;
  z-index: 1;
  color: #333333;

  div {
    height: 40px;
    padding: 12px;
    cursor: pointer;
  }

  div:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  div:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  div:hover {
    background-color: #e6f1f3;
  }
}

// Nationality Dropdown ------------------
.dropdown-countries {
  background-color: white;
  border-radius: 8px;
  border: 2px solid #4fa2b4;
  position: absolute;
  top: 110%;
  z-index: 1;
  color: #333333;
  max-height: 285px;
  overflow-y: scroll;
  scrollbar-color: #adadad transparent;

  div {
    padding: 12px;
    cursor: pointer;
  }

  div:hover {
    background-color: #e6f1f3;
  }
}
